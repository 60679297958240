
import faker from "../../public/extras/faker.min.js";

class Person {
	constructor() {
		this.name = faker.name.firstName();
    
		this.phone  = faker.phone.phoneNumber('011-###-###');
		this.email = faker.internet.email();
    this.address = faker.address.streetAddress();
    this.location = faker.address.city();
    this.postalcode = faker.random.number({min: 1, max: 4});
    this.province = faker.address.state();
    this.web = faker.internet.url();
    this.type_client = faker.random.number({min: 1, max: 4});

    this.cuit = faker.random.number({min: 1, max: 999999999});
    this.iibb = faker.random.number({min: 1, max: 999999999});

	}
}

export default Person;